<template>
  <div style="margin: 0 20px 0 20px">
    <el-row style="height: 50px; display: flex; align-items: center">
      <el-col :span="4"
        ><div
          style="font-weight: bold; font-size: 20px; height: 100%; color: black"
        >
          农机作业数据分析平台
        </div></el-col
      >
      <el-col :span="4" :offset="15">
        <div style="height: 50px; display: flex; align-items: center">
          <span></span>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px" :gutter="20">
      <el-col :span="20">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-card>
              <div>
                <div style="font-size: 15px">在线农机总数</div>
              </div>
              <div
                style="
                  font-weight: bold;
                  font-size: 16px;
                  color: blue;
                  margin-top: 15px;
                "
              >
                {{ cardData.zxnjzs }} 台
              </div>
              <div style="font-size: 10px; margin-top: 10px">
                <span v-if="false" style="font-size: 10px; color: green">
                  ↑ {{ 50 }}%</span
                >
                <span v-else style="font-size: 10px; color: red">
                  ↓ {{ cardData.zxnjzsjzr }}%</span
                >
                <span style="font-size: 10px; color: gray"> 较昨日</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <div>
                <div style="font-size: 15px">今日作业面积</div>
              </div>
              <div
                style="
                  font-weight: bold;
                  font-size: 16px;
                  color: blue;
                  margin-top: 15px;
                "
              >
                {{ cardData.jrzymj }} 亩
              </div>
              <div style="font-size: 10px; margin-top: 10px">
                <span v-if="false" style="font-size: 10px; color: green">
                  ↑ {{ 50 }}%</span
                >
                <span v-else style="font-size: 10px; color: red">
                  ↓ {{ cardData.jrzymjjzr }}%</span
                >
                <span style="font-size: 10px; color: gray"> 较昨日</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <div>
                <div style="font-size: 15px">作业完成率</div>
              </div>
              <div
                style="
                  font-weight: bold;
                  font-size: 16px;
                  color: blue;
                  margin-top: 15px;
                "
              >
                {{ cardData.zywcl }} %
              </div>
              <div style="font-size: 10px; margin-top: 10px">
                <span v-if="false" style="font-size: 10px; color: green">
                  ↑ {{ 50 }}%</span
                >
                <span v-else style="font-size: 10px; color: red">
                  ↓ {{ cardData.zywcljzr }}%</span
                >
                <span style="font-size: 10px; color: gray"> 较昨日</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <div>
                <div style="font-size: 15px">农机利用率</div>
              </div>
              <div
                style="
                  font-weight: bold;
                  font-size: 16px;
                  color: blue;
                  margin-top: 15px;
                "
              >
                {{ cardData.njlyl }} %
              </div>
              <div style="font-size: 10px; margin-top: 10px">
                <span v-if="false" style="font-size: 10px; color: green">
                  ↑ {{ 50 }}%</span
                >
                <span v-else style="font-size: 10px; color: red">
                  ↓ {{ cardData.njlyljzr }}%</span
                >
                <span style="font-size: 10px; color: gray"> 较昨日</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="6">
            <el-row>
              <el-card style="width: 373px">
                <div>
                  <div style="font-size: 15px">农机品牌分布</div>
                </div>

                <div ref="chart" style="width: 373px; height: 200px">
                  <PieChart :chartData="BrandPieData"> </PieChart>
                </div>
              </el-card>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="6">
                <el-card style="width: 373px">
                  <div>
                    <div style="font-size: 15px">区域作业面积</div>
                  </div>
                  <div ref="chart" style="width: 373px; height: 240px">
                    <BarChart
                      :chartData="BrandBarData"
                      :xAxisData="BrandxAxisData"
                      barColor="#91CC75"
                      :showTooltip="true"
                      :showLegend="true"
                    />
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="18">
            <el-card>
              <div>
                <div style="font-size: 15px">作业区域分布</div>
              </div>
              <!-- <div style="height: 623px"></div> -->
              <div class="map-container">
                <div ref="mapChart" style="width: 100%; height: 700px;">
                  <div v-if="loading" class="loading-spinner">
                  <div class="loader"></div>
                </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row>
          <el-col>
            <el-card>
              <div>
                <div style="font-size: 15px">24小时作业趋势</div>
              </div>
              <div ref="chart" style="width: 290px; height: 200px">
                <AreaChart
                  :chartData="TrendsAreaData"
                  :xAxisData="TrendsxAxisData"
                  areaColor="#91CC75"
                />
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col>
            <el-card>
              <div>
                <div style="font-size: 15px">合作社服务面积排行</div>
              </div>
              <div ref="chart" style="width: 373px; height: 200px">
                <RankChart
                  :rankData="serviceAreaData"
                  chartTitle="合作社服务面积排行"
                  barColor="#91CC75"
                />
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col>
            <el-card>
              <div>
                <div style="font-size: 15px">作业效率排行</div>
              </div>
              <div ref="chart" style="width: 373px; height: 200px">
                <RankChart
                  :rankData="workEfficiencyData"
                  unit="亩/小时"
                  chartTitle="作业效率排行"
                  barColor="#91CC75"
                />
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {
  selectCarBrandCountByAreaCode,
  selectHomeworkTrends,
  selectRegionalHomework,
  selectServiceAreaRanking,
  todayData,
  efficiencyRanking,
} from "@/api/sjfx";
import {
  machineTrajectoryData
} from "@/api/njjg";
import moment from "moment";
import BarChart from "./components/BarChart";
import AreaChart from "./components/AreaChart";
import PieChart from "./components/PieChart";
import RankChart from "./components/RankChart";
import * as echarts from "echarts";
require("echarts/extension/bmap/bmap");
export default {
  components: {
    PieChart,
    AreaChart,
    BarChart,
    RankChart,
  },
  data() {
    return {
        serviceAreaData: [
        // { name: "张三", value: 120 },
        // { name: "李四", value: 200 },
        // { name: "王五", value: 150 },
        // { name: "赵六", value: 80 },
        // { name: "孙七", value: 70 },
        // { name: "周八", value: 110 },
        // { name: "吴九", value: 130 },
      ],
      WorkEfficiencyData: [
        // { name: "张三", value: 120 },
        // { name: "李四", value: 200 },
        // { name: "王五", value: 150 },
        // { name: "赵六", value: 80 },
        // { name: "孙七", value: 70 },
        // { name: "周八", value: 110 },
        // { name: "吴九", value: 130 },
      ],
      BrandPieData: [],
      BrandxAxisData: [],
      BrandBarData: [],
      TrendsxAxisData: [],
      TrendsAreaData: [],
      cardData: {
        zxnjzs: "",
        zxnjzsjzr: 0,
        jrzymj: "",
        jrzymjjzr: 0,
        zywcl: "",
        zywcljzr: 0,
        njlyl: "",
        njlyljzr: 0,
      },
      trajectoryData: [], // 从API获取轨迹数据
      mapCenter: [116.404, 39.915], // 初始中心点
      mapZoom: 15,
      loading: false,
    }
   },
  mounted() {
    this.machineTrajectoryData();
    this.initMap();

    //隐藏头部和底部
    let header = document.querySelector(".header");
    let noShow = document.querySelector(".noShow");
    // let homeManage = document.querySelector(".home-manage");
    let footer = document.querySelector(".footer");
    header.style = "display:none";
    footer.style = "display:none";
    noShow.style = "display:none";
    // homeManage.style = "padding-top:0;width:100vw;";
  },
  created() {
    //今日数据
    todayData().then((res) => {
      // console.log("todayData", res);
      this.cardData = {
        zxnjzs: res.data.zxnjzs.zxnjzs,
        zxnjzsjzr: res.data.zxnjzs.zxnjzsjzr,
        jrzymj: res.data.jrzymj.jrzymj,
        jrzymjjzr: res.data.jrzymj.jrzymjjzr,
        zywcl: res.data.zywcl.zywcl,
        zywcljzr: res.data.zywcl.zywcljzr,
        njlyl: res.data.njlyl.njlyl,
        njlyljzr: res.data.njlyl.njlyljzr,
      };
    });
    //作业效率排行
    efficiencyRanking().then((res) => {
      // console.log("efficiencyRanking", res);
      this.workEfficiencyData = res.data;
    });
    //合作社服务面积排行
    selectServiceAreaRanking().then((res) => {
      // console.log("合作社服务面积排行", res);
      this.serviceAreaData = res.data;
    });
    selectCarBrandCountByAreaCode().then((res) => {
      // console.log("selectCarBrandCountByAreaCode", res);
      this.BrandPieData = res.data;
    });
    selectHomeworkTrends().then((res) => {
      // console.log("selectHomeworkTrends", res);
      this.TrendsxAxisData = res.data.xAxisData;
      this.TrendsAreaData = res.data.areaData;
    });
    selectRegionalHomework().then((res) => {
      // console.log("selectRegionalHomework", res);
      this.BrandxAxisData = res.data.xAxisData;
      this.BrandBarData = res.data.areaData;
    });


  },
  methods: {
    //轨迹数据
    async machineTrajectoryData() {
        this.loading = true;
        //获取当天开始时间和结束时间
        const dayDate = new Date();
        const startTime = moment(dayDate).format("YYYY-MM-DD 00:00:00")
        const endTime = moment(dayDate).format("YYYY-MM-DD 23:59:59")
        let params = {
          year: "",
          orderContent: "",
          cropCode: "",
          companyName: "",
          startTime: startTime,
          endTime: endTime,
        }
        let res = await machineTrajectoryData(params)
        if (res.code == 0) {
          this.trajectoryData = res.data;
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
        this.initMap();
    },
   // 数据清洗和转换
   processTrackData(rawData) {
      return rawData.flatMap(track =>
        track.points.map(point => ({
          machineType: track.machineType,
          carNumber: track.carNumber,
          driverName: track.driverName,
          workTime: track.workTime,
          workArea: track.workArea,
          companyName: track.companyName,
          coords: this.removeDuplicatePoints(point.coords),
          lineStyle: {
            color: "#00BFFF",
            width: 1,
            opacity: 0.7,
            curveness: 0, // 路径弯曲度
          },
        }))
      )
    },

    // 去除连续重复点
    removeDuplicatePoints(points) {
      return points.filter((point, index) => {
        if (index === 0) return true
        const prev = points[index - 1]
        return !(point[0] === prev[0] && point[1] === prev[1])
      })
    },
    // 计算轨迹边界中心点
    calculateTrackCenter(tracks) {
      let minLng = Infinity,
          maxLng = -Infinity,
          minLat = Infinity,
          maxLat = -Infinity

      // 遍历所有轨迹点
      tracks.forEach((track) => {
        track.points.forEach((point) => {
          point.coords.forEach((coord) => {
            const [lng, lat] = coord;
            minLng = Math.min(minLng, lng);
            maxLng = Math.max(maxLng, lng);
            minLat = Math.min(minLat, lat);
            maxLat = Math.max(maxLat, lat);
          });

        });
      });

      // 计算中心点
      const centerLng = (minLng + maxLng) / 2
      const centerLat = (minLat + maxLat) / 2

      // 计算推荐缩放级别（经验公式）
      const lngDiff = maxLng - minLng
      const latDiff = maxLat - minLat
      const zoom = Math.floor(
        9 - Math.log(Math.max(lngDiff, latDiff * 1.5)) / Math.LN2
      )

      return {
        center: [centerLng, centerLat],
        zoom: Math.min(17, Math.max(14, zoom))
      }
    },

    // 自动居中方法
    autoCenter() {
      const { center, zoom } = this.calculateTrackCenter(this.trajectoryData)
      this.mapCenter = center
      this.mapZoom = zoom
      // 更新地图视图
      const chart = echarts.getInstanceByDom(this.$refs.mapChart)
      chart.setOption({
        bmap: {
          center: this.mapCenter,
          zoom: this.mapZoom
        }
      })
    },
    initMap() {
      const chartDom = this.$refs.mapChart
      const myChart = echarts.init(chartDom)

      // 首次加载自动居中
      if (this.trajectoryData.length > 0) {
        this.autoCenter()
      }
      // else {
      //   var myGeo = new BMap.Geocoder();
      //   // 将地址解析结果显示在地图上，并调整地图视野
      //   myGeo.getPoint('宣汉县', function(point){
      //     this.mapCenter = point;
      //     this.mapZoom = 15;
      //   })
      // }

      myChart.setOption({
        bmap: {
          center: this.mapCenter, // 初始中心点
          zoom: this.mapZoom,
          roam: true, // 允许缩放和拖拽
          mapStyle: {
            styleJson: [{
              "featureType": "highway",
              "elementType": "all",
              "stylers": { "visibility": "off" }
            }]
          }
        },
        series: [{
          type: 'lines',
          coordinateSystem: 'bmap',
          data: this.processTrackData(this.trajectoryData),
          polyline: true,
          large: true,  // 启用大数据模式
          largeThreshold: 2000, // 数据量>2000时启用优化
          progressiveChunkMode: 'mod', // 分片渲染
          blurSize: 2,      // GPU加速模糊
          layerId: 'track',  // 独立渲染层
          lineStyle: {
            color: '#55a8ff',
            width: 1,
            opacity: 0.5
          },
          effect: {
            show: false,
          }
        }],
        tooltip: {
          trigger: 'item',
          formatter: params => {
            const points = params.data.coords
            return `
              <div style="padding:5px">
                <div>农机类型：${params.data.machineType}</div>
                <div>车牌号：${params.data.carNumber}</div>
                <div>驾驶员：${params.data.driverName}</div>
                <div>本次作业时长：${params.data.workTime}</div>
                <div>服务对象：${params.data.companyName}</div>
              </div>
            `
          }
        }
      });

      // 关联百度地图实例
      const bmap = myChart.getModel().getComponent('bmap').getBMap();
      bmap.setMapType(BMAP_HYBRID_MAP);
      bmap.setMaxZoom(17.4);

      // 添加地图控件
      bmap.addControl(new BMap.NavigationControl());

      // 轨迹点击事件
      // myChart.on('click', { seriesIndex: 0 }, (params) => {
      //   const track = this.multiTrackData.find(t => t.id === params.dataIndex + 1)
      //   console.log('完整轨迹数据:', track.points)
      // })
    }
  }
};
</script>
<style lang="less" scoped>
.loading-spinner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* 背景半透明 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1); /* 边框颜色 */
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f; /* 转圈颜色 */
  animation: spin 1s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
